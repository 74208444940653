
 .profile-head{
width:100%;
/*top:46px;
position: absolute;*/
top: 59px;
position: sticky;
z-index: 9999999;
/* height: 190px;*/
-webkit-transition: .3s ease all;
-moz-transition: .3s ease all;
-o-transition: .3s ease all;
transition: .3s ease all;
background-image: -webkit-gradient(linear,left top,right top,from(#3852a4),to(#76c14f));
background-image: -webkit-linear-gradient(left,#3852a4,#76c14f);
background-image: -moz-linear-gradient(left,#3852a4,#76c14f);
background-image: -o-linear-gradient(left,#3852a4,#76c14f);
background-image: linear-gradient(to right,#3852a4,#76c14f);
background-repeat: repeat-x;
}
#user-profile .m-1{
margin:0.5rem!important;
}
#user-profile .mt-1{
margin-top: 0.5rem!important;
/* margin-left: 21px;*/
}
#user-profile .card-header{
background-color: transparent;
border-bottom: none;
}
#user-profile ul.list-inline li {
display: inline-block;
}
#user-profile a:hover {
outline: 0;
text-decoration: none;
color: #fff;
}
.avatar {
position: relative;
width: 30px;
vertical-align: bottom;
white-space: nowrap;
border-radius: 1000px;
display: inline-block;
}
.avatar img {
width: 100%;
max-width: 100%;
height: auto;
border: 0;
border-radius: 1000px;
}
.avatar-sm {
width: 32px;
}
.pull-up {
-webkit-transition: all .25s ease;
-moz-transition: all .25s ease;
-o-transition: all .25s ease;
transition: all .25s ease;
}
#user-profile .users-list li+li {
margin-left: -14px;
}
.font-medium-4 {
font-size: 1.4rem !important;
}
.font-medium-1 {
font-size: 1.1rem !important;
}
.yellow.darken-2 {
color: #fbc02d!important;
}
.white{
color:#fff;
}